import { getObjectValue } from "../utils";


export const getCellValue = (data, path) => {
  const val = getObjectValue(data, path);
  //console.log("from getVal:", data, path, "getting: ", val);

  return (val instanceof Array) ? val.reduce((ini, cur) => {
    //ini = (ini instanceof Object ? getVal(ini, "") : ini && ini.toString())
    return (ini ? (ini + ", ") : "") + (cur instanceof Object ? getCellValue(cur, "") : cur.toString())
  }, "") : (val instanceof Object ? getCellValue(val.value || val.label || val.title || val.name || val.id || null, "") : val)
}
