import { asArray, getPropertyObject, ifNaN, isValid } from '../utils';
import { postData } from '../../../services/api-services'
import queryString from 'query-string'
import { resolveCondition } from '../condition';
import { asBool } from '../form/input/utils';
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function runDelay(timer) {
  await sleep(timer);
  console.log(`Waited for ${timer / 1000} seconds`);
}

async function runTimer({ payload: { actions, label, seconds, data = {} }, meta: { run, observerDispatch: observer } }) {
  actions = (actions || "").split(";").filter(e => e);
  label = label || "default"
  seconds = Number(ifNaN(seconds, 0.0));

  if (actions.length) {
    observer({
      type: "attach",
      label,
      dettach: (timer => () => clearTimeout(timer))(setTimeout(() => {
        run(actions, data);
        observer({ type: "dettach", label })
      }, seconds * 1000))
    })

    console.log(`Started ${label} timer for ${seconds} seconds`);
  } else {
    observer({ type: "dettach", label })
    console.log(`Stopped ${label} timer`);
  }
}

const postErrorDialog = (codigo, mensaje, acciones, detalle) => ({
  icon: "fas exclamation-triangle",
  color: "danger",
  title: "Error",
  actions: acciones,
  bodymd: detalle || [
    `###### Ha ocurrido un error${codigo ? ` (*${codigo}*)` : ""}.`,
    mensaje && `**Descripci\u00F3n:** *${mensaje}*`
  ].join("\n\n")
})

const PostActionError = (show = false, retry = false, save = false, abort = false) => ({ show: asBool(show), retry: asBool(retry), save: asBool(save), abort: asBool(abort) })

const defaultPostErrorOptions = {
  abort: PostActionError(false, false, true, true),
  prompt: PostActionError(true, true, true, false),
  inform: PostActionError(true, false, true, false),
  ignore: PostActionError(false, false, true, false),
}

const postAction = async (props) => {
  const { payload: { as, data, path, overwrite, method, params, exception }, dispatch, scope, meta, done, localData, pageData, fromException = false } = props;
  const body = as ? { [as]: data } : (data ? data : undefined)

  const payload = await postData("/exec", { method, ...(params) }, scope, body, body ? "POST" : "GET")

  console.log("just posted", fromException, payload)

  const saveResult = () => dispatch({
    type: "set-data",
    path: path,
    overwrite: isValid(overwrite) ? asBool(overwrite) : true,
    payload: payload
  })

  const { exception: resp_exep, status: resp_status, message: resp_message, detail: resp_detail, show, retry, save, abort } = asArray(payload?._response || {})[0];
  const closeAction = (action, close) => {
    close();
    if (action == "Reintentar") postAction({ ...props, fromException: true})
    else done()
  }

  if (resp_exep || ifNaN(Number(resp_status || 200), 500) >= 500) {
    
    const errorOptions = [show, retry, save, abort].some(isValid)
      ? PostActionError(show, retry, save, abort)
      : defaultPostErrorOptions[exception || "nodef"] || PostActionError()

    const dialogOptions =  (errorOptions.retry && "Reintentar;Aceptar") || "Aceptar";

    if (errorOptions.save) saveResult()
    if (errorOptions.abort) selector.abort({ meta })

    if (errorOptions.show) {
      meta.alert(postErrorDialog(resp_status, resp_message, dialogOptions, resp_detail), { closeAction, markdownProps: { meta, localData, pageData } })
      return "pause";
    }

    if (errorOptions.retry) return postAction({ ...props })

  } else saveResult()

  if (fromException) done()
}

const alertAction = async ({ payload, meta, dispatch, done, pageData, localData }) => {
  const closeAction = (action, close) => {
    dispatch({ type: "set-data", path: payload.path || "alert_action_result", payload: action })
    close();
    done();
  }

  if (payload.message) {
    meta.alert(payload.message, { closeAction, markdownProps: { meta, localData, pageData } });
    return "pause";
  }
}

const eventAction = async ({ payload, meta }) => {
  const timeout = 1000 * (payload.timeout === undefined ? 5 : ifNaN(Number(payload.timeout), 5))

  if (payload.message) {
    meta.event(payload.message, { type: payload.as || 'info', timeout });
  }
}

const routineAction = async ({ payload, meta }) => {

  const it = payload.iteration || 1;
  const actions = (payload.actions instanceof Array)
    ? payload.actions
    : (typeof payload.actions === "string"
      ? payload.actions.split(";").filter(e => e)
      : []
    );

  const tasks = []
  const stack = `${payload._stack || ""}/${payload.name || "unnamed"}`;
  let innerActions = (payload.inner || []);

  for (let i = 0; i < it && (actions.length || innerActions.length); i++) {
    tasks.push(...actions.map(action => ({
      _stack: stack,
      name: action,
      extraData: { ...(payload.data), index: i },
      type: "global"
    })))

    innerActions = (payload.inner || []).map(task => ({ _stack: stack, extraData: { ...(payload.data), index: i }, ...task }));

    tasks.push(...innerActions);
  }

  if (tasks.length)
    console.log("adding tasks", tasks);
  meta.actionDispatch({
    type: "add-tasks",
    tasks
  });
}

const setAction = async ({ payload, dispatch, meta }) => {
  payload.set.forEach(set => {
    if (set.cookie) {
      if (set.data) meta.cookies.set(set.cookie, set.data, { path: "/", maxAge: set.maxage, sameSite: "strict", secure: true })
      else meta.cookies.remove(set.cookie)
    } else {
      dispatch({ type: "set-data", path: set.path, payload: set.data, overwrite: payload.overwrite })
    }
  })
}

const selector = {
  event: eventAction, // {message, as}
  alert: alertAction, // {message}
  routine: routineAction, //{ actions, data, iteration }
  abort: async ({ meta }) => { meta.actionDispatch({ type: "abort" }) },
  log: async ({ payload }) => console.log("log-action", payload), //{""}
  set: setAction, //{set:[{cookie, path, data}, overwrite]}
  form: async ({ payload, dispatch }) => dispatch({ type: "activate-form", name: payload.form, data: payload.data, entity: payload.entity }), //{form, data, entity}
  show: async ({ payload, dispatch }) => dispatch({ type: "show-module", name: payload.board, show: (JSON.parse(payload.show) ? true : false) }), //{board, show}
  post: postAction, // {path, method, params, data, as, overwrite}
  goto: async ({ payload, meta }) => { if (meta) meta.history.push(`/${payload.page}?${queryString.stringify(payload.params)}`) }, // {page, params}
  refresh: async ({ meta }) => { if (meta) meta.refresh() },
  delay: async ({ payload }) => runDelay(payload.seconds * 1000 || 3000),
  timer: runTimer, // { seconds, label, actions, data }
  close: async ({ close }) => close(),
  refreshed: async ({ meta }) => meta.refreshed(),
}

export const execAction = async ({ action = {}, data = {}, meta, outerData = {}, dispatch = () => { }, scope, close = () => { }, done = () => { } }) => {
  //const props = action.property && getPropertyList({ innerdata: data, outerdata: outerData, properties: action.property })
  const { condition, ...properties } = action

  const cookies = action.type === 'set' ? meta.cookies.getAll() : null;

  const props = getPropertyObject(data, outerData, properties, cookies)
  //console.log("executing: ", action.name, action.type, props, data, action.property);

  if (resolveCondition(condition, { local: data, global: outerData, this: props })) {
    const exec = selector[action.type]

    if (!exec) throw Error(`${action.type} is not a valid action.`)
    else return exec({ payload: { ...props }, dispatch, scope, close, meta, done, pageData: outerData, localData: data })
  }
}

