
export const testCard = {
  "name": "test/card",
  "type": "empleado",
  "title": "Pruebas de Tablero:Card",
  "orden": "20",
  "data": JSON.stringify(
    {
      header: {
        data: {
          title: "Hola, Magno",
          detail: "Esto es un ejemplo de un item con un texto interesante que se puede informar al usuario.",
          content: [
            {
              content: ":img[96504E7E-BCA9-48A1-9DFE-DEAE4AB16B12]{width=75px alt=Logo}\n\n### Propagas",
              size: "xs",
              align: "center"
            },
            {
              content: ":img[96504E7E-BCA9-48A1-9DFE-DEAE4AB16B12]{width=75px alt=Logo}\n\n### Next",
              size: "xs",
              align: "center"
            },
            {
              content: ":img[96504E7E-BCA9-48A1-9DFE-DEAE4AB16B12]{width=75px alt=Logo}\n\n### Transgas",
              size: "xs",
              align: "center"
            },
            {
              content: ":img[96504E7E-BCA9-48A1-9DFE-DEAE4AB16B12]{width=75px alt=Logo}\n\n### Raigas",
              size: "xs",
              align: "center"
            },
            {
              content: ":img[96504E7E-BCA9-48A1-9DFE-DEAE4AB16B12]{width=75px alt=Logo}\n\n### Siglo XXI",
              size: "xs",
              align: "center"
            }
          ]
        }
      },
      resultado: [
        {
          status: "Registrado"
        }
      ]
    }
  ),
  "reader": [],
  "form": [
    {
      "name": "test",
      "entity": "test",
      "type": "header",
    }
  ],
  "action": [
    {
      name: "mensajito",
      type: "event",
      message: '"Este es un evento"'
    },
    {
      "name": "postthis",
      "type": "post",
      "method": "\"guardarcambios\"",
      "data": "\"@local\"",
      "as": "\"datos\"",
      "path": "\"resultado\"",
      "param": "null",
      "exception": "ignore"
    },
    {
      "name": "logthis",
      "type": "log",
      "info": "\"@local\""
    },
    {
      name: "setthis",
      type: "set",
      set: [
        { path: "rutaimportante", compute: true, exp: `" '@local.texto;'.concat(' hola leandro')" ` },
      ],
    }
  ],
  "board": [
    {
      name: "test",
      type: "card",
      path: "header.data.content",
      hidden: true
    }
  ],
  "entity": [
    {
      name: "test",
      field: [
        {
          name: "content",
          type: "list",
          label: "Tarjetas",
          entity: "card" 
        },
        {
          name: "stest",
          label: "Visualizacion",
          type: "section",
          modes: "read",
          readmd: `:align[mid] :board[test]`
        },
      ]
    },
    {
      name: "card",
      field: [
        {
          name: "s0",
          type: "section",
          label: "Contenido",
        },
        {
          name: "title",
          type: "text",
          label: "Titulo",
          size: "md"
        },
        {
          name: "s0_1",
          type: "section",
        },
        {
          name: "detail",
          type: "textarea",
          label: "Detalle",
          size: "md"
        },
        {
          name: "s1",
          type: "section",
          label: "Forma",
        },
        {
          name: "align",
          type: "select",
          label: "Alineación",
          options: JSON.stringify(["left", "center", "right"]),
          value: JSON.stringify("left"),
          size: "xs"
        },
        {
          name: "icon",
          type: "text",
          label: "Icono",
          size: "xs",
          condition: [
            {
              not: true,
              type: "eq",
              a: JSON.stringify("@local.align"),
              b: JSON.stringify("center")
            }
          ]
        },
        {
          name: "s01",
          type: "section"
        },
        {
          name: "size",
          type: "select",
          label: "Tamaño",
          options: JSON.stringify(["xs", "sm", "md", "lg"]),
          value: JSON.stringify("sm"),
          size: "xs"
        },
        {
          name: "color",
          type: "select",
          label: "Color",
          options: JSON.stringify(["light", "info", "dark", "warning", "danger", "secondary", "primary"]),
          value: JSON.stringify("light"),
          size: "xs"
        },
        {
          name: "bg",
          type: "select",
          label: "Color de Fondo",
          options: JSON.stringify(["light", "info", "dark", "warning", "danger", "secondary", "primary"]),
          value: JSON.stringify("light"),
          size: "xs"
        },
      ]
    },
  ]
}

export default testCard

