import { useEffect, useState, useMemo } from 'react';
import useSwr, { mutate } from 'swr'
import isEqual from 'react-fast-compare';

import { apiFetch } from '../../../services'
import { getObjectValue } from "../utils";

const Reader = ({ name, method, property, path, context, data, dispatch, action }) => {
    const [ctxDispatch, account] = [context.dispatch, context?.scope?.current]
    const prp = useMemo(() => JSON.stringify(property ? property : {}), [property])
    const datakey = method ? ['/exec', method, account, prp] : null
    const [currentData, setCurrentData] = useState(getObjectValue(data, path))

    const {
        data: _newData,
        isValidating: loading
    } = useSwr(datakey, (key, method, account, property) => apiFetch(key, { method, ...(JSON.parse(property)) }, account), { focusThrottleInterval: 30000, dedupingInterval: 5000 })

    const newData = useMemo(() => {
        //console.log("newdata?", getObjectValue(data, path))
        return datakey ? _newData : getObjectValue(data, path)
    }, [datakey, _newData, data, path])

    useEffect(() => {
        if (!isEqual(currentData, newData)) {
            console.log("new data in reader:", name, currentData, newData);
            setCurrentData(newData);
            if (datakey && newData) dispatch({ type: "set-data", path, payload: newData, override: true });
            action({ reader: name, old: currentData})();
        }
    }, [currentData, name, action, newData, path, dispatch])

    useEffect(() => {
        if (loading) {
            ctxDispatch({ type: "refreshing" })
            return () => ctxDispatch({ type: "refreshed" })
        }
    }, [ctxDispatch, loading])

    useEffect(() => {
        if (!loading && context.shouldRefresh && datakey) {
            mutate(datakey)
        }
    }, [loading, context.shouldRefresh, datakey])

    return null
}


export default Reader
