import React, { useEffect, useMemo, useRef } from 'react'
import SimpleLabel from './simplelabel'
import { useDebouncer } from './usedebouncer'

const requiredvalidation = "Completa este campo"

const TextArea = ({ name, label, placeholder, value, path, data, setNewVal, readonly, required, max, min }) => {
	const _id = `input${path.replace(/(\.\.)|([._-]+)/g, '')}${name}`
	const current = (data !== undefined ? data : value)
	const [buffer, debounce] = useDebouncer(current, setNewVal)
	const notsetted = !buffer && buffer !== ""
	
	useEffect(() => {
		if (data === undefined && current !== undefined) {
			setNewVal(current)
		}
	}, [current, path, setNewVal, data])

	const props = useMemo(() => {
		const init = () => {
			if (notsetted) {
				if (inputref.current) inputref.current.setCustomValidity("")
				debounce("", true)
			}
		}

		const props = {
			onChange: (required)
				? event => {
					if (notsetted && required) event.target.setCustomValidity(requiredvalidation)
					else event.target.setCustomValidity("")

					debounce(event.target.value || undefined, false)
				}
				: event => debounce(event.target.value || undefined, false),
			onClick: init
		}

		if (readonly) props.disabled = true
		if (required) props.required = true
		if (max) props.maxLength = max
		if (min) props.minLength = min

		return props
	}, [notsetted, debounce, readonly, min, max, required])

	const inputref = useRef(null)

	return (
		<React.Fragment>
			<SimpleLabel label={label} htmlFor={_id} />
				<textarea id={_id} ref={inputref}
					className="form-control form-control-sm text-weight-normal"
					name={name}
					rows={3}
					placeholder={placeholder}
					value={buffer || ''}
					{...props}
				/>
		</React.Fragment>
	)
}

export default TextArea;
