import { useLayoutEffect } from "react";
import { useReducer, useCallback, useState } from "react";
import { useEffect } from "react";


export const useDebouncer = (current, onChange = (value, files) => { }) => {
	const [buffer, setBuffer] = useReducer((state, action) => ({ ...state, ...action }), { wto: undefined, value: current });
	const [cache, setCache] = useState(current);

	useEffect(() => {
		if (current !== cache) {
			if (current !== buffer.value) setBuffer({ value: current })
			setCache(current)
		}
	}, [current, cache, buffer.value])

	const debounce = useCallback((value, now = false) => {
		setBuffer({ exec: { value, now }, value });
	}, [])

	useLayoutEffect(() => {
		if (buffer.exec) {
			clearTimeout(buffer.wto);
			if (buffer.exec.now) onChange(buffer.exec.value)
			else setBuffer({ wto: setTimeout(() => onChange(buffer.exec.value), 350) })
			setBuffer({ exec: null });
    }
	}, [buffer.wto, onChange, buffer.exec])

	//const debounce = useCallback((value, now = false) => {
	//	clearTimeout(buffer.wto);
	//	if (now) onChange(value) //files don't bounce.
	//	else setBuffer({ wto: setTimeout(() => onChange(value), 350), value })
	//}, [buffer.wto, onChange])

	return [buffer.value, debounce];
}

