import React from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './authroute'
import { ApplicationPaths } from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const LoginMenu = () => {
  const logoutPath = { pathname: ApplicationPaths.LogOut, state: { local: true } }

  /* Hay garantía de que {user} siempre tendrá un usuario */
  return (
    <AuthContext.Consumer>
      {user => (
        <div className="dropdown user-menu">
          <Link id="dropdownAccount" to={ l=>l } className="text-md nav-link text-dark dropdown-toggle" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
            <FontAwesomeIcon icon="user-circle" />
          </Link>
          <ul className="dropdown-menu dropdown-menu-right pt-0" aria-labelledby="dropdownAccount" data-boundary="window">
            <li className="dropdown-item user-header text-center bg-secondary">
              <p>{user ? user.name : 'publico'}</p>
            </li>
            <li className="dropdown-item user-footer">
              <Link type="button" to={logoutPath} className="float-right btn btn-outline-secondary" >
                <span>Salir</span>
                <FontAwesomeIcon icon={["fas", "sign-out-alt"]} className="ml-2" />
              </Link>
            </li>
          </ul>
        </div>
        
      )}
    </AuthContext.Consumer>
  )
}


export default LoginMenu
