import { useState, useEffect } from 'react'

const Event = ({ data, processing, init, locationSearch, action}) => {
	
	const current = locationSearch //JSON.stringify(resolveExpression('"@global.node"', { global: data, local: {} }))
	const [state, setState] = useState(current)
	const [selfinit, setSelfInit] = useState(false);

	useEffect(() => {
		if (current !== state) {
			setState(current);

			if (selfinit && !processing) {
				//console.log("Event triggered: ", processing, selfinit, JSON.parse(state), JSON.parse(current));
				//console.log("Event triggered: ", processing, selfinit, state, current);
				action()
			}
		}
	}, [current, state, processing, init, action, selfinit])


	useEffect(() => { if (init) setSelfInit(true) }, [init])

	return null;
}


export default Event;

