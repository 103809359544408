import React, { useLayoutEffect, useMemo } from 'react'
import Control from '../control'
import { getObjectValue, isValid, resolveExpression } from '../../utils';
import { useComputed } from './usecomputed';
import { useOptionList } from '../input/useoptionlist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { asBool } from '../input/utils';
import { buttonCell } from '../../board/table/parsecolumns';
import { useSizeStyle } from '../usesizestyle';
import MarkDown from '../../../markdown';

const setval = (dispatch, path, type) => {

  const filefn = async (val, files = []) => {
    dispatch({ type: "set-value", path: path, payload: val })
    if (files?.length) {
      let i
      for (i = 0; i < files.length; i++) {
        const file = files[i];
        const filepath = `${path}_files.${i}`
        const reader = new FileReader();

        reader.onload = () => {
          dispatch({
            type: "set-value",
            path: filepath,
            payload: {
              name: file.name,
              type: file.type,
              size: file.size,
              text: reader.result
            }
          })
        }
        reader.onerror = () => { console.warn("Failed to read file!\n\n" + reader.error); }
        reader.readAsText(file);
      }
    }
  }

  switch (type) {
    case "list": return async (payload = {}) => dispatch({ type: "set-value", path: path.concat(".push()"), payload })
    case "file": return filefn
    default: return async (val, _path) => dispatch({ type: "set-value", path: _path || path, payload: val })
  }
}
const testtable = DisplayBoard => ({ node }) => {
  const props = node.children[1].children[1].children[1].children[0].value.split(' ')
  const rows = node.children[1].children[1].children[3]
  const boardName = props.shift()
  const className = props.join(' ');
  const align = node.children[1].children[1].children[1].properties.align;
  const aligns = {
    left: 'justify-content-start',
    center: 'justify-content-center',
    right: 'justify-content-end',
  }
  //console.log("la tabla", props, className, align, rows, node);
  //return (<span>Aqui debio haber ido una tabla</span>);

  return (
    <div className={["row", aligns[align || "left"]].join(' ')}>
      <div className={className}>
        <DisplayBoard boardName={boardName} />
      </div>
    </div>
  );


}

const Adios = (props) => {
  console.log("adios", props)
  return <div><span>{"¡¡Adios!!"}</span>{props.children || ""}</div>
}

const ListField = ({ fieldData, rowIndex, field, path, isreadmode, dispatch, toggleReadMode, DisplayBoard, ...rest }) => {
  const data = getObjectValue(fieldData, (field.path || field.name));
  const readmd = useMemo(() => field.readmd && isreadmode && resolveExpression(JSON.stringify(field.readmd), { global: rest.pageData, local: fieldData }), [fieldData, rest.pageData, field.readmd, isreadmode])
  const setNewVal = useMemo(() => setval(dispatch, path, field.type), [dispatch, path, field.type]);

  useLayoutEffect(() => {
    if (isValid(data) && toggleReadMode && asBool(data) != isreadmode) {
      //console.log("section to", isreadmode ? "edit" : "read", "mode");
      toggleReadMode(asBool(data))
    }
  })

  const onClickEdit = () => {
    //console.log("section clicked to", isreadmode ? "edit" : "read", "mode");
    toggleReadMode(!isreadmode)
    if (isValid(data))
      setNewVal(!isreadmode)
  }

  const props = {
    ...rest,
    ...field,
    setNewVal,
    value: useMemo(() => field.value && resolveExpression(field.value, { global: rest.pageData, local: fieldData }), [fieldData, rest.pageData, field.value]),
    dispatch,
    parent: fieldData,
    data,
    DisplayBoard
  }

  if (field.type === "number" && fieldData?.currency) props.dataCurrency = fieldData.currency;

  useComputed({ computed: field.computed, data, expression: field.expression, setNewVal: props.setNewVal, pageData: props.pageData, localData: fieldData });
  const option = useOptionList({ type: field.type, options: field.options, global: props.pageData, local: fieldData, label_path: field.option_label_path, value_path: field.option_value_path })

  if (field.type !== "list") {
    props.dispatch = undefined;
    props.parent = undefined;
    props.pageData = undefined;
    props.scope = rest.pageData?.meta?.scope;
    props.DisplayBoard = undefined;
  }

  const buttonSize = useSizeStyle(field.size || "sm")
  //if (field.type === "button") console.log("form button control", props.value, data, field);
  if (field.hideonform) return null
  else if (field.type === "button") return (
    <div className={"mb-1 form-group form-group-sm col align-self-center"} style={buttonSize}>
      {buttonCell(rest.pageData.meta, field)(fieldData, rowIndex, props.value)}
    </div>
  )
  else if (field.type === "section") return (
    <div className={`col-12 ${field.label && "mt-3"} mb-1`} >
      {(field.label) &&
        <h6 className="m-0">{field.label}
          {toggleReadMode && <span onClick={onClickEdit} className="ml-1" style={{ cursor: "pointer" }} title={isreadmode ? "Modo edición" : "Modo lectura"} >
            {isreadmode
              ? <FontAwesomeIcon icon={['far', 'edit']} />
              : <FontAwesomeIcon icon={['far', 'eye']} />
            }
          </span>
          }
        </h6>
      }
      <MarkDown children={readmd} DisplayBoard={DisplayBoard} localData={fieldData} close={rest.close} pageData={ rest.pageData } meta={ rest.pageData?.meta } />
    </div >
  )
  else
    return (
      <Control key={path}
        {...props}
        option={option}
        path={path}
      />
    )
}


export default ListField;
