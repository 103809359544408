import React from 'react'
import { asBool } from '../page/form/input/utils'
import { isValid } from '../page/utils'

const NoDisplayBoard = () => <span>No board in context.</span>

const BoardComponent = ({ node, children, DisplayBoard = NoDisplayBoard, path, fromForm, entity, hideifempty, localData, meta, pageData, close, ...props }) => {
  
  const boardProps = {
    boardName: node.children[0]?.value || 'unknown',
    fromForm: isValid(fromForm) ? asBool(fromForm) : true,

    localPath: path,
    entity,
    close,
    hideifempty: asBool(hideifempty)
  }

  return <DisplayBoard {...boardProps} localData={{ ...localData, ...props }} />
}

export default BoardComponent