import React, { useState, useMemo } from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal'

import { resolveCondition } from '../condition'
import List from './list'

const solverfn = (_processing, global, local) => (condition, style) => {
	const isDisabled = _processing ? { disabled: true } : {}
	const result = resolveCondition(condition, { global, local });
	const ishidden = (result === "hide" || !result) ? { style: { display: "none", ...style } } : { style }
	const isdisabled = result === "disable" ? { disabled: true } : {}
	return { ...isDisabled, ...ishidden, ...isdisabled }
}

export const CardLike = ({ title, modified, form_data, dispatch, struct, buttons: Buttons = () => null, close, closefn, pageData, hasCloseActions = false, processing, processingFlagPath, formRef, ...rest }) => {
	const [state, setState] = useState({});
	const buttonShouldDisplay = useMemo(() => solverfn(processing, pageData, form_data), [processing, pageData, form_data ])

	return (
		<div className="card rounded-0">
			<div className="card-header">
				<h5 className="card-title">{title}</h5>
				<div className="card-tools">
					{modified && <span className="badge badge-warning">!</span>}
					{!state.maximize && <button type="button" className="btn btn-tool" data-card-widget="collapse" onClick={() => setState({ minimize: !state.minimize })} ><FA icon={state.minimize ? "plus" : "minus"} /></button>}
					{!state.minimize && <button type="button" className="btn btn-tool" data-card-widget="maximize" onClick={() => setState({ maximize: !state.maximize })} ><FA icon={state.maximize ? "compress-alt" : "expand-alt"} /></button>}
					{hasCloseActions && <button type="button" className="btn btn-tool" {...(buttonShouldDisplay())} onClick={close(form_data, closefn)} ><FA icon="times" /></button>}
				</div>
			</div>
			<ol className="card-body list-group-flush p-0 mb-0" style={state.maximize ? { overflowY: "scroll" } : null} >
				<List struct={struct} data={[form_data]} dispatch={dispatch} pageData={pageData} {...rest} />
			</ol>
			<div className="card-footer">
				<div className="form-row justify-content-md-end">
					<Buttons data={form_data} close={closefn} solver={buttonShouldDisplay} processingFlagPath={processingFlagPath} formRef={formRef} />
				</div>
			</div>
		</div>
	)
}

const customStyles = {
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.60)',
		zIndex: "1500"
	},
	content: {
		top: '10vh',
		bottom: 'auto',
		margin: 'auto',
		padding: '0px',
		minHeight: "100px",
		maxHeight: "80vh"
		//transform: 'translate(-40%, -25%)'
	}
};


Modal.setAppElement('#root')

export const ModalLike = ({ title, modified, form_data, dispatch, struct, buttons: Buttons = () => null, close, closefn, pageData, hasCloseActions = false, processing, processingFlagPath, formRef, ...rest }) => {
	const [state, setState] = useState({});

	const isDisabled = processing ? { disabled: true } : {}

	const [modalIsOpen, setIsOpen] = React.useState(true);
	const buttonShouldDisplay = useMemo(() => solverfn(processing, pageData, form_data), [processing, pageData, form_data])

	/*function openModal() {
		setIsOpen(true);
	}
*/
	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		console.log("afterOpenModal")
	}

	function closeModal() {
		setIsOpen(false);
	}

	return (
		<Modal
			className="col-11 col-sm-9 col-md-8 col-lg-6 col-xl-5 m-auto"
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel={title}
			shouldCloseOnOverlayClick={false}
		>
			<div className="card rounded-0 m-0" style={{ maxHeight: "inherit" }} >
				<div className="card-header">
					<h5 className="card-title">{title}</h5>
					<div className="card-tools">
						{modified && <span className="badge badge-warning">!</span>}
						<button type="button" className="btn btn-tool" data-card-widget="maximize" onClick={() => setState({ maximize: !state.maximize })} ><FA icon={state.maximize ? "compress-alt" : "expand-alt"} /></button>
						{hasCloseActions && <button type="button" className="btn btn-tool" {...(buttonShouldDisplay())} onClick={close(form_data, closefn)} ><FA icon="times" /></button>}
					</div>
				</div>
				<ol className="card-body list-group-flush p-0 mb-0" style={{ overflowY: "auto" }} >
					<List struct={struct} data={[form_data]} dispatch={dispatch} pageData={pageData} {...rest} />
				</ol>
				<div className="card-footer">
					<div className="form-row justify-content-md-end">
						<Buttons data={form_data} close={closefn} solver={buttonShouldDisplay} isDisabled={isDisabled} processingFlagPath={processingFlagPath} formRef={formRef} />
					</div>
				</div>
			</div>
		</Modal>
	)
}


export const HeaderLike = ({ Header, title, modified, form_data, dispatch, struct, buttons: Buttons = () => null, pageData, processing, processingFlagPath, formRef, ...rest }) => {
	const buttonShouldDisplay = useMemo(() => solverfn(processing, pageData, form_data), [processing, pageData, form_data])

	return (
		<section className="container-fluid">
			<div className="row">
				<Header />
			</div>
			<ol className="list-group-flush p-0 mb-2" style={{ marginLeft: -8, marginRight: -8 }}>
				<List struct={struct} data={[form_data]} dispatch={dispatch} pageData={pageData} {...rest} />
			</ol>
			<div className="form-row justify-content-md-end">
				<Buttons data={form_data} solver={buttonShouldDisplay} processingFlagPath={processingFlagPath} formRef={formRef} />
			</div>

		</section>
	)
}

export const HeaderInlineLike = ({ title, modified, form_data, dispatch, struct, buttons: Buttons = () => null, pageData }) => {
	return (
		<section className="container-fluid">
			<div className="row align-items-center">
				<div className="col mb-2">
					<h1 className="d-inline mr-2">{title}</h1>
					{/* {modified && <span className="badge badge-info float-right">!</span>} */}
				</div>
				<Buttons data={form_data} />
			</div>
		</section>
	)
}

