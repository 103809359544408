import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LoginMenu from '../auth/loginmenu'
import ScopeSelector from './scope'
import SideNav from './sidenav'
import Main from '../main'

const Layout = ({ context, ...props }) => {
  const [isSmall, setIsSmall] = useState(window.matchMedia('(max-width: 575px)').matches);

  useEffect(() => {
    const mediaMatch = window.matchMedia('(max-width: 575px)');
    const handler = e => setIsSmall(e.matches)
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler)
  }, [])

  const smMarginT = isSmall && context.scope.current ? { marginTop: "calc(6rem + 1px)" } : undefined
  const smMinH = isSmall && context.scope.current ? { minHeight: "97px" } : undefined

  return (
    <div className="wrapper">
      <nav className="main-header navbar navbar-expand navbar-light">
        <div className="container-fluid">

          <div className="row mx-0" style={{ width: "100%" }}>
            <div className="col order-first">
              <button type="button" data-widget="pushmenu" className="mr-auto btn btn-outline-default">
                <FontAwesomeIcon icon="bars" />
              </button>

            </div>

            <div className="col order-3 d-flex" style={{ maxWidth: "110px" }}>
              <button type="button" onClick={() => context.dispatch({ type: "refresh-data", payload: true })} className="btn ml-auto" data-tip={context.lastRefresh || "click para refrescar"}>
                <i id="spinrefreshbtn" className="fas fa-sync" />
                <FontAwesomeIcon icon="sync" {...(context.refreshing && { spin: true })} />
              </button>
              <LoginMenu />
            </div>

            <div className="col-12 order-last col-sm-auto order-sm-2">
              <ScopeSelector scope={context.scope} dispatch={context.dispatch} />
            </div>

          </div>
        </div>
        <ReactTooltip effect="solid" place="bottom" multiline className="py-1 px-2" />

      </nav>
      <SideNav smMarginT={smMarginT} smMinH={smMinH} pages={context.page} menu={context.menu} tipo={context.scope.current?.tipo} empresa={context.scope.current?.empresa} />
      <div className="content-wrapper" style={smMarginT}>
        <Main {...props} />
      </div>
    </div>
  )
}

export default Layout;

