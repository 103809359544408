import React, { useEffect, useCallback } from 'react'
import JSONEditor from '../../../jsoneditor'
import { tryJSON } from '../../utils'
import SimpleLabel from './simplelabel'
import { useDebouncer } from './usedebouncer'
import { asBool } from './utils'


const JSONInput = ({ name, type, label, placeholder, value, path, data, setNewVal, json, readonly, clearable, required }) => {
	const _id = `input${path.replace(/(\.\.)|([._-]+)/g, '')}${name}`
	const current = (data !== undefined ? data : value)
	const [buffer, debounce] = useDebouncer(current, setNewVal)
	const notsetted = !buffer && buffer !== ""
	const isjson = asBool(json);

	useEffect(() => {
		if (data === undefined && current !== undefined) {
			debounce(current, true)
		}
	}, [current, path, debounce, data])

	const onChangeJSON = useCallback(obj => {
		debounce(isjson ? obj : JSON.stringify(obj),true);
	}, [isjson, debounce])

	const onChangeText = useCallback(txt => {
		debounce(isjson ? tryJSON(txt) : txt);
	}, [isjson, debounce])

	return (
		<React.Fragment>
			<SimpleLabel label={label} htmlFor={_id} />
			<div className="form-control form-control-sm text-weight-normal" style={{ height:"auto", padding: 0 } } >
				<JSONEditor
					navigationBar={false}
					statusBar={false}
					languaje="es"
					search={false}
					modes={['tree', 'view', 'code']}
					onChangeJSON={onChangeJSON}
					onChangeText={onChangeText}
					json={isjson ? buffer : null}
					text={isjson ? null : buffer}
				/>
			</div>
		</React.Fragment>
	)
}

export default JSONInput;
