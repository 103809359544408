import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from './menuitem';

const empresas = {
	gp: {
		nombre: "Portal GP",
	},
	prog: {
		nombre: "Propagas",
		logo: "logo-propagas-300x123.png"
	},
	dip: {
		nombre: "next",
		logo: "logo-next-w400.png"
	},
	paug: {
		nombre: "Pauligas",
		logo: null
	},
	raig: {
		nombre: "Raigas",
		logo: null
	},
	rafg: {
		nombre: "Rafagas",
		logo: null
	},
	butg: {
		nombre: "Butagas",
		logo: null
	},
	mang: {
		nombre: "Manelogas",
		logo: null
	},
	sigg: {
		nombre: "Siglo XXI",
		logo: null
	},
	fixg: {
		nombre: "Fixogas",
		logo: null
	},
	dcor: {
		nombre: "Dipsa Coral",
		logo: null
	},
	gasp: {
		nombre: "Gas�polis",
		logo: null
	},
	tran: {
		nombre: "Transgas",
		logo: null
	},
	afeg: {
		nombre: "Aferme",
		logo: null
	}
}

const getMenu = (tipo, menu, pages) => {
	if (!menu && pages && pages.length) {
		menu = []

		pages
			.filter(page => !!page.icon)
			.forEach(page => {
				page.type
					.split(";")
					.forEach(type => {
						menu.push({
							type,
							page: page.name,
							label: page.title || page.name,
							icon: page.icon
						})
					})

			})
	}

	return menu?.filter(opt => opt.type === tipo);
}

const SideNav = ({ smMinH, smMarginT, menu, pages = [], empresa, tipo }) => {
	if (!empresa) empresa = "gp";

	menu = getMenu(tipo, menu, pages)

	return (
		<aside className="main-sidebar sidebar-md sidebar-light-primary elevation-2">
			<Link to="/" className="brand-link" style={smMinH}>
				{/* <img src={empresas[empresa].logo} alt="Logo" className="brand-image text-center float-center" style={{ maxHeight: "45px", float: "none" }} /> */}
				<span className="logo d-block text-center">{empresas[empresa].nombre}</span>
			</Link>
			<div className="sidebar p-0" style={smMarginT}>
				{menu
					? (
						<nav className="mt-2" >
							<ul className="nav nav-pills nav-sidebar flex-column" >
								<MenuItem menu={menu} tipo={tipo} />
							</ul>
						</nav>
					)

					: (
						<div className="overlay mt-5 text-center ">
							<FontAwesomeIcon icon="sync-alt" size="2x" spin />
						</div>
					)
				}
			</div>
		</aside>
	)
}

export default SideNav;