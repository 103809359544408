import React from 'react'

import Button from './button'

const ActionList = ({ buttons = [], close = () => { }, data, processingFlagPath, ...rest }) =>
	buttons.map((btn, i) => {
		const props = {};
		(btn.flags?.split(";") || []).forEach(flag => props[flag] = true)

		return <Button key={i} idx={i} triggerFn={fn => fn(data, close, processingFlagPath)} {...props} {...btn} {...rest} />
	})


export default ActionList;
