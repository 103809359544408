import Auth from '../components/auth/service'
import config from '../config'
import queryString from 'query-string'

const _apiurl = config.apiUrl;
const _apiscopeprefix = "scope-"
const _reqInit = {
  mode: "cors",
  cache: "default",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Accept": "application/json",
  }
}

const _myFetch = (fn = async () => { }) => fn()
  .then(resp => {
    if (resp.status === 401) {
      console.log("since unauthorized, I should try to re-signIn", resp);

      return Auth.silentSignIn().then(result => {
        if (result.signed) return fn()
        else return resp
      })
    }
    return resp;
  })
  .then(resp => {
    const _response = { ok: resp.ok, status: resp.status, message: resp.statusText }

    return resp.ok ? resp.json().then(val => ({ _response, ...val })) : { _response }
  })
  .catch(reason => ({
    _response: {
      ok: false,
      exception: true,
      message: reason.message
    }
  }))

//export const apiFetch = async (action) => fetch(`${_apiurl}${action}`, _reqInit).then(resp => resp.json())
export const apiFetch = (action, params = {}, scope = {}) => {
  return postData(action, params, scope, undefined, "GET")
}

export const postFile = (schema, scope = {}, file, fileName = "archivo") => {
  const form = new FormData();
  form.append("file", file, fileName)

  return postData("/file", { schema }, scope, undefined, "POST", form, {})
}

export const buildUrl = (action, params, scope, schema) => {
  const url = `${_apiurl}${action}`
  const queryobj = {}

  for (let [key, value] of Object.entries(params)) {
    if (value !== null && value !== undefined)
      queryobj[key] = value
    }

   if (schema) queryobj["schema"] = schema;

  if (scope?.empresa) {
    queryobj[`${_apiscopeprefix}empresa`] = scope.empresa
    queryobj[`${_apiscopeprefix}tipo`] = scope.tipo
    queryobj[`${_apiscopeprefix}id`] = scope.id
  }

  return `${url}?${queryString.stringify(queryobj)}`
}

export const postData = async (action, params = {}, scope = {}, bodyParams, method = "POST", formData = null, headers = null) => {
  const fullurl = buildUrl(action, params, scope)

  const fn = () => Auth.getAccessToken().then(token => fetch(fullurl, {
    ..._reqInit,
    method,
    ...(bodyParams && { body: JSON.stringify(bodyParams) }),
    ...(formData && { body: formData }),
    headers: {
      ...(headers || _reqInit.headers),
      "Authorization": `Bearer ${token}`
    }
  }))
  return _myFetch(fn)
}

export const getScope = async (callback) =>   
  config.testScopes
    ? Promise.resolve(callback(config.testScopes))
    : apiFetch("/meta/user", {}, {}).then(scopes => callback(scopes))





