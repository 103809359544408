import React from 'react'
import SimpleInput from './input/simpleinput'
import SimpleSelect from './input/simpleselect'
import CustomSelect from './input/customselect'
import SimpleList from './input/simplelist'
import { useSizeStyle } from './usesizestyle'
import SimpleNumber from './input/simplenumber'
import { useMemo } from 'react'
import SimpleCheckbox from './input/simplecheckbox'
import { asBool } from './input/utils'
import TextArea from './input/textarea'
import JSONInput from './input/jsoninput'
import FileInput from './input/fileinput'

const Control = React.memo( ({ size, ...props}) => {
	const sizeStyle = useSizeStyle(size || (props.type === "list" && "full") || "sm");

	const Input = useMemo(() => {
		switch (props.type) {
			case "checkbox":
				return SimpleCheckbox
			case "text":
			case "date":
			case "datetime-local":
			case "email":
			case "password":
			case "tel":
			case "time":
			case "week":
			case "month":
				return SimpleInput
			case "file":
				return FileInput
			case "textarea":
				return TextArea
			case "json":
				return JSONInput
			case "number":
				return SimpleNumber
			case "select":
				if (!asBool(props.select_multi) && !asBool(props.select_custom))
					return SimpleSelect
				else
					return CustomSelect
			case "list":
				return SimpleList
			default:
				throw new Error(`What kind of input is "${props.type}"`)
		}
	}, [props.type, props.select_multi, props.select_custom])

	return (
		<div className={"mb-1 form-group form-group-sm col align-self-center"} style={sizeStyle}>
			<Input {...props} />
		</div>
	)
})

export default Control;
