import React from 'react'
import { FontAwesomeIcon as FA, FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal'
import { firstValid } from '../page/utils';
import MarkDown from '../markdown';

Modal.setAppElement('#root')

const customStyles = {
	overlay: {
		//backgroundColor: 'rgba(0, 0, 0, 0.65)',
		zIndex: "1500"
	},
	content: {
		top: '10vh',
		bottom: 'auto',
		margin: 'auto',
		padding: '0px',
		minHeight: "100px",
		maxHeight: "80vh"
		//transform: 'translate(-40%, -25%)'
	}
};

const Header = ({ title = "Información", color = "info", icon = "fas info-circle" }) => (
	<h5 className="card-title" >
		<FontAwesomeIcon icon={icon.split(" ")} size="2x" className={`text-${color} pr-2`} style={({ marginTop: "-6px", marginBottom: "-6px" })} />
		<span>{title}</span>
	</h5>)

const standardTypes = [{
	type: "info",
	color: "info",
	icon: "fas info-circle",
	title: "Información"
},
{
	type: "question",
	color: "info",
	icon: "fas question-circle",
	title: "Pregunta"
}, {
	type: "warning",
	color: "warning",
	icon: "fas exclamation-triangle",
	title: "Atención"
}, {
	type: "error",
	color: "danger",
	icon: "fas times-circle",
	title: "Error"
}]

const DialogAlert = ({ message, options, style, close }) => {
	let body, actions = [];

	if (typeof message === 'string') {
		body = message;
	} else {
		body = message.message || message.body || []
		if (!(body instanceof Array)) body = body.split(";").filter(b => !!b)

		body = firstValid(message.messagemd, message.textmd, message.text, message.md, message.bodymd, body.join("\n\n"));

		actions = message.action || message.actions || []
		if (!(actions instanceof Array)) actions = actions.split(";").filter(a => !!a)
	}

	const content = { ...(standardTypes.find(i => i.type === (message?.type || "info"))), ...message }

	return (
		<div className="container" style={style}>
			<div className="row">

				<Modal
					className="col-11 col-sm-9 col-md-8 col-lg-6 col-xl-5 m-auto"
					isOpen={true}
					style={customStyles}
					contentLabel="Alert Modal"

				>
					<div className={`card card-outline card-${content.color || "info"} m-0`} style={{ maxHeight: "inherit" } } >
						<div className="card-header">
							<Header title={content.title} color={content.color} icon={content.icon} />
							<div className="card-tools">
								<button type="button" className="btn btn-tool" onClick={() => options.closeAction("x", close)} ><FA icon="times" /></button>
							</div>
						</div>
						<div className="card-body pb-1" style={{ overflowY: "auto" }}>
							<MarkDown children={body} {...options.markdownProps} close={() => options.closeAction("x", close)} />
						</div>
						{!!actions.length &&
							<div className="card-footer row m-0 justify-content-center justify-content-md-end">
							{actions.map((action, i) => <div key={i} className="col-6 col-md-auto" ><button className="btn btn-sm btn-default mb-1 mr-1 w-100" style={({ minWidth: "5rem" })} onClick={() => options.closeAction(action, close)} >{action}</button></div>)}
							</div>
						}
					</div>
				</Modal>

			</div>
		</div>
	)
}

/*<ol className="card-body list-group-flush p-0 mb-0" style={state.maximize ? { overflowY: "scroll" } : null} >
					<span>{message && (message.message || message)}</span>
				</ol>
				*/

export default DialogAlert
