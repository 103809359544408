import React, { useEffect } from 'react'
import { asBool } from './utils'


const SimpleCheckbox = ({ name, type, label, value, path, data, setNewVal, readonly, required }) => {
	const _id = `input${path.replace(/(\.\.)|([._-]+)/g, '')}${name}`
	const current = data === undefined ? undefined : asBool((data !== undefined ? data : value))
	
	useEffect(() => {
		if (data === undefined && current !== undefined) {
			setNewVal(current)
		}
	}, [current, setNewVal, data])

	const props = {}

	if (readonly) props.disabled = true
	if (required) props.required = true
	
	return (
		<React.Fragment>
			<div className="form-check" >
				<input id={_id} 
					className="form-check-input text-weight-normal"
					type={type}
					checked={current || false}
						
					onChange={event => setNewVal(event.target.checked)}
					name={name}
					{...props}
				/>
				<label className="form-check-label" htmlFor={_id} >{label}</label>
			</div>
		</React.Fragment>
	)
}

export default SimpleCheckbox;
