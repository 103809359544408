import React from 'react'


const validPChildren = ['strong', 'em', 'code', 'b', 'a', 'span', 'fa', 'icon', 'cache', 'br', 'align', 'size', 'button', 'img']


const validAlign = {
  start: 'left',
  mid: 'center',
  middle: 'center',
  end: 'right',
  left: 'left',
  center: 'center',
  right: 'right',
  izq: 'left',
  cen: 'center',
  der: 'right',
  izquierda: 'left',
  centro: 'center',
  centrado: 'center',
  derecha: 'right',
}


const validSize = {
  xxs: "xx-small",
  xs: "x-small",
  sm: "small",
  md: "medium",
  lg: "large",
  xl: "x-large",
  xxl: "xx-large",
  "xx-small": "xx-small",
  "x-small": "x-small",
  small: "small",
  medium: "medium",
  large: "large",
  "x-large": "x-large",
  "xx-large": "xx-large"
}

const alignclasses = {
  left: { p: "text-left", div: "justify-content-start" },
  center: { p: "text-center", div: "justify-content-center" },
  right: { p: "text-right", div: "justify-content-end" },
  none: { p: "", div: "justify-content-around" },
}

const PComponet = ({ children, node: { children: nodechildren = [] } }) => {
  const hasElements = nodechildren.some(node => node.type != 'text' && !validPChildren.includes(node.tagName) )

  const findTextTag = (nodes, tagname) => nodes.find(node => node.tagName === tagname && node.children[0]?.type === "text")?.children[0].value
  //validAlign[(nodechildren[0]?.tagName == 'align' && nodechildren[0].children[0]?.type == "text" && nodechildren[0].children[0].value ) || "none"];

  const align = validAlign[findTextTag(nodechildren, "align") || "none"]
  const size = validSize[findTextTag(nodechildren, 'size') || "md"]

  const alignClass = alignclasses[align] || alignclasses.none;

  return hasElements ? <div className="mb-2 mt-1 container-fluid" ><div className={`row ${alignClass.div}`} >{children}</div></div> : <p className={`mb-2 ${alignClass.p}`} style={{ fontSize: size} } >{children}</p>
}


export default PComponet