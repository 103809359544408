import React from 'react'
import { getTriggerAction } from '../page/utils'


const validColors = {
  info: 'info',
  warning: 'warning',
  success: 'success',
  danger: 'danger',
  secondary: 'secondary',
  primary: 'primary',
  dark: 'dark',
  light: 'light',
  'outline-info': 'outline-info',
  'outline-warning': 'outline-warning',
  'outline-success': 'outline-success',
  'outline-danger': 'outline-danger',
  'outline-secondary': 'outline-secondary',
  'outline-primary': 'outline-primary',
  'outline-dark': 'outline-dark',
  'outline-light': 'outline-light',
}


const validSize = {
  sm: "btn-sm",
  lg: "btn-lg",
}

const ButtonComponent = ({ title, children, node, action, actions, meta, close, localData, color, size, ...props}) => {

  const clickHandle = e => {
    e.preventDefault()
    getTriggerAction({ actionNames: action || actions, meta })({...localData, ...props}, close)()
  }

  return <button type="button" title={title} onClick={clickHandle} className={`btn py-0 px-2 mr-1 btn-${validColors[color || 'default']} ${validSize[size] || ''}`}>{children}</button>
}

export default ButtonComponent