import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { getTriggerAction } from '../utils'

const iconStyle = {
	top: "-15px",
}

const Card = ({
	value = "",
	label = "",
	icon = "fas coins",
	bg = "bg-gradient-info",
	btn,
	btn_actions,
	btn_icon,
	meta,
	data
}) => {

	const clickHandler = () => {
		getTriggerAction({ actionNames: btn_actions, meta })(data)();
	}

	return (
		<div className="col mx-auto" style={{ minWidth: "225px", maxWidth: "425px" }} >
			<div className={`small-box ${bg}`}>
				<div className="inner">
					<h4>{value}</h4>
					<p className="mb-0">{label}</p>
				</div>
				<div className="icon">
					<i className="fas" style={iconStyle}><FontAwesomeIcon icon={icon.split(" ")} /></i>
				</div>
				{(btn_actions || null)
					? (
						<Link to={l => l} className="small-box-footer" onClick={clickHandler} >
							<p className="mr-2 d-inline mb-0" >{btn || "Ver Detalles"}</p>
							{(btn_icon || null) && <FontAwesomeIcon icon={btn_icon.split(" ")} />}
						</Link>
					)
					: (
						<div className="small-box-footer"><br /></div>
					)}
			</div>
		</div>
	)
}

export default Card