import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { firstValid } from '../page/utils';

const validColors = {
  info: 'info',
  warning: 'warning',
  success: 'success',
  danger: 'danger',
  secondary: 'secondary',
  primary: 'primary',
  dark: 'dark',
  light: 'light',
}


const validSize = {
  xs: "xs",
  sm: "sm",
  md: "1x",
  lg: "2x",
  xl: "3x",
  "1x": "1x",
  "2x": "2x",
  "3x": "3x",
  "4x": "4x",
  "5x": "5x",
  "6x": "6x",
  "7x": "7x",
  "8x": "8x"
}

const IconComponent = ({ node: { children: [node] }, size, color }) => {
  const icon = node?.value || "";

  return <FontAwesomeIcon icon={icon.split(" ")} size={ validSize[size] || "1x" } className={color ? `text-${firstValid(validColors[color], "dark")}`: ''} />
}

export default IconComponent