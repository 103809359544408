import { useReducer } from "react"
import { useEffect } from "react"
import { resolveExpression } from "../../utils"
import { useMemo } from "react"
import * as isEqual from "react-fast-compare"



export const useOptionList = ({ type, options, global, local, label_path, value_path }) => {
	const [optionsObj, setOptionsObj] = useReducer((a, b) => isEqual(a, b) ? a : JSON.parse(JSON.stringify(b)), null)
	
	useEffect(() => {
		if ((type || "select") !== "select") return;
		const newobj = resolveExpression(options, { global, local }) || null 
		setOptionsObj(newobj)
	}, [type, options, global, local, setOptionsObj])

	return useMemo(() => {
		const obj = optionsObj;
		const optionsArr = (obj instanceof Array) ? obj : (typeof obj === "string" ? obj.split(";") : [obj])

		return optionsArr.filter(e => !!e).map(item =>
			typeof item === "string"
				? { value: item, label: item }
				: { ...item, value: item[value_path || "value"], label: item[label_path || "label"] }
		)
	}, [optionsObj, label_path, value_path])
}

