import { useEffect, useMemo, useRef } from 'react'
import { resolveExpression } from '../../utils'

export const computeThis = (expression, obj = {}) => {
	const exp = resolveExpression(expression, obj)
	try {
		/*
		 ¡Este código puede ser muy peligroso!
		 si la página admite en la expresión una variable que es recibida como parámetro de la página haría vulnerable la seguridad del usuario.
		 */
		//console.log('computing this: ', exp, expression, pageData);

		/*eslint no-new-func: off*/
		return Function('"use strict"; return (' + exp + ')')();
	}
	catch (error) {
		console.log('not able to compute', exp, error.message);
	}

	return exp;
}

export const useComputed = ({ computed, setNewVal, expression, pageData, localData }) => {
	//const current = useMemo(() => data, [data])

	const ref = useRef({cnt: 0, wto: undefined});

	const computedValue = useMemo(() => computed ? computeThis(expression, { global: pageData, local: localData }) : undefined, [computed, expression, pageData, localData])

	useEffect(() => {
		if (computed && ref.current.cnt < 5) {
			//console.log('computed field: ', computedValue, ref.current.cnt)
			setNewVal(computedValue);

			ref.current.cnt += 1;
			clearTimeout(ref.current.wto);
			setTimeout(() => { ref.current.cnt = 0 }, 500)

		}
	}, [setNewVal, computed, computedValue])

	/*useEffect(() => {
		if (data === undefined && current !== undefined) {
			dispatch({ type: "set-value", path, payload: current })
		}
	}, [current, path, dispatch, data])
*/
}


