import React from 'react'
import { Link } from 'react-router-dom'

const style = { fontWeight: 550, fontSize: "0.8rem" }

const ListLabel = ({ htmlFor = "", label = "", canAdd = false, add = () => { } }) => (
	<label className="mb-0 mt-3 mr-auto" htmlFor={htmlFor} style={style}  >
		<h6 className="d-inline mt-2" >{label}</h6>
		{canAdd && <span className="ml-2"> (<Link to={ l => l} onClick={() => { add() }}>Agregar</Link>)</span>}
	</label>
)

export default ListLabel;