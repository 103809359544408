import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MarkDown from '../../markdown'
import { asArray, firstValid, getTriggerAction, resolveExpression } from '../utils'
import { buildUrl } from '../../../services'
import './item.css'

const sizes = {
  xs: {
    cardSize: { minWidth: "120px", maxWidth: "300px" },
    //cardSize: { width: "" },
    cardClass: "col-5 col-sm-auto",
    iconSize: '2x',
    image: { width: "75px" },
    header: image => ({ height: image ? "50px" : "20px" })
  },
  sm: {
    cardSize: { minWidth: "225px", maxWidth: "425px" },
    cardClass: "col-10 col-sm-8 col-md-auto",
    iconSize: '2x',
    image: { width: "75px" },
    header: image => ({ height: image ? "75px" : "45px" })
  },
  md: {
    cardSize: { minWidth: "375px", maxWidth: "575px" },
    cardClass: "col-12 col-sm-9 col-md-6 col-lg-auto",
    iconSize: '3x',
    image: { width: "100px" },
    header: image => ({ height: image ? "100px" : "50px" })
  },
  lg: {
    cardSize: { minWidth: "525px", maxWidth: "725px" },
    cardClass: "col-12 col-sm-12 col-md-9 col-lg-6 col-xl-auto",
    iconSize: '4x',
    image: { width: "150px" },
    header: image => ({ height: image ? "150px" : "75px" })
  }
}

const verticalAlign = {
  start: "start",
  top: "start",
  mid: "center",
  middle: "center",
  center: "center",
  end: "end",
  bot: "end",
  bottom: "end"
}

const cardColors = {
  info: 'info',
  warning: 'warning',
  success: 'success',
  danger: 'danger',
  secondary: 'secondary',
  primary: 'primary',
  dark: 'dark',
  light: 'light',
}

const Item = ({
  content: contentmd,
  header,
  title,
  detail,
  icon,
  image,
  align,
  valign,
  shape,
  size,
  color = "light",
  bg = "light",
  action: actionNames,
  meta,
  close,
  localData,
  pageData,
  ...data
}) => {
  const content = useMemo(() => resolveExpression(
    JSON.stringify(asArray(firstValid(contentmd, [title ? `#### ${title}` : '', '', detail || ''])).join("\n")),
    { local: data, global: {} }
  ), [data, contentmd, title, detail])

  const opt = sizes[size] || sizes.sm;
  const itemColor = firstValid(cardColors[color], 'light')
  const backgroundColor = firstValid(cardColors[bg], 'light')
  const hasHeader = (align == "center" && (icon || image)) || !!header

  const iconClass = [
    align === "right" ? 'order-3' : 'order-1',
    shape === "circle" ? 'rounded-circle' : 'rounded-lg',
    icon ? `bg-gradient-${itemColor} p-3` : ''
  ]

  const imageClass = [
    shape === "circle" ? 'rounded-circle' : 'rounded-lg',
    `border-${itemColor}`
  ]

  const cardOptions = actionNames ? {
    style: { cursor: "pointer" },
    onClick: getTriggerAction({ actionNames, meta })(data, close),
  } : {};

  return (
    <div className={ opt.cardClass } style={{...opt.cardSize, flexGrow: 1}} >
      <div className={`card bg-${backgroundColor} ${align === "center" ? 'border' : 'border-thick'} border-${itemColor} ${hasHeader ? 'border-0' : ''} `} {...cardOptions} >
        {
          hasHeader &&
          <React.Fragment>
            <div className={`card-header bg-gradient-${itemColor} d-flex align-items-center`} style={{ ...opt.header(!!image), marginBottom: image ? `calc(${opt.image.width} /2)` : undefined }}>
              <h4 className="m-0">{header}</h4>
            </div>
            {image &&
              <div style={{ position: "absolute", left: "50%", top: `calc(${opt.header(!!image).height} - ${opt.image.width} / 2)`, marginLeft: `calc(-${opt.image.width} /2)` }}>
                <img className={['border', ...imageClass].join(' ')} src={buildUrl('/file', { id: image }, meta.scope)} alt="Imagen" {...opt.image} />
              </div>
            }
          </React.Fragment>
        }
        <div>
          <div className="card-body d-flex p-3">
            {
              align != 'center' && (icon || (image && !hasHeader)) &&
              <div className={['m-1', 'align-self-center', 'border', ...iconClass].join(' ')}>
                {
                  (icon && <FontAwesomeIcon icon={icon.split(' ')} size={opt.iconSize} />) ||
                  (image && <img className={['border', ...imageClass].join(' ')} src={buildUrl('/file', { id: image }, meta.scope)} alt="Imagen" {...opt.image} />) ||
                  null
                }
              </div>
            }
            <div className={`px-2 order-2 flex-grow-1 ${verticalAlign[valign] ? "align-self-" + verticalAlign[valign] : ""} text-${align == "center" ? "center" : "left"}`}>
              <MarkDown children={content} meta={meta} close={close} localData={data} pageData={pageData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Item