import ExcelExport, { defaultDataType } from 'export-xlsx';
import { colGrow } from './parsecolumns';

const ExcelExportSettings = ({ struct, title, }) => ({
	fileName: title || "Archivo",
	workSheets: [
		{
			sheetName: 'datos',
			startingRowNumber: 1,
			tableSettings: {
				data: {
					//tableTitle: 'datos',
					headerDefinition: struct.filter( col => col.type !== "button" ).map(col => ({
						name: col.title || col.label || col.name,
						key: col.name,
						width: 20 * colGrow(col.size),
						dataType: (
							(col.type === 'number' && !col.percent && defaultDataType.number) ||
							((col.type === 'date' || col.type === 'datetime-local') && defaultDataType.date) ||
							(col.type === 'number' && !col.percent && defaultDataType.percentage) ||
							defaultDataType.string
						)
					}))
				},
			},
		},
	],
})

export const exportToExcel = ({ data, ...props }) => {
	const excelExport = new ExcelExport();
	const settings = ExcelExportSettings({ ...props })

	console.log("exportToExcel", settings);

	excelExport.downloadExcel(settings, data);
}



