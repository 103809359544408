import React, { useEffect, useCallback, useMemo, useRef } from 'react'
import SimpleLabel from './simplelabel'
import { useDebouncer } from './usedebouncer'
import { asBool } from './utils'

const requiredvalidation = "Completa este campo"

const SimpleInput = ({ name, type, label, placeholder, value, path, data, setNewVal, json, readonly, clearable, required, max, min }) => {
	const _id = `input${path.replace(/(\.\.)|([._-]+)/g, '')}${name}`
	const current = (data !== undefined ? data : value)
	const [buffer, debounce] = useDebouncer(current, setNewVal)
	const notsetted = !buffer && buffer !== ""
	const isjson = asBool(json);

	useEffect(() => {
		if (data === undefined && current !== undefined) {
			setNewVal(current)
		}
	}, [current, path, setNewVal, data])

	const props = useMemo(() => {
		const init = () => {
			if (notsetted && clearable) {
				if (inputref.current) inputref.current.setCustomValidity("")
				debounce("", true)
			}
		}

		const props = {
			onChange: (clearable && required) || isjson
				? event => {
					if (notsetted && required) event.target.setCustomValidity(requiredvalidation)
					else if (isjson) {
						try {
							JSON.parse(event.target.value)
							event.target.setCustomValidity("")
						} catch(e) {
							event.target.setCustomValidity("Debe ser un JSON válido")
						}
					} else event.target.setCustomValidity("")

					debounce(event.target.value || undefined, false)
				}
				: event => debounce(event.target.value || undefined, false),
			onClick: init
		}

		if (readonly) props.disabled = true
		if (required && !clearable) props.required = true
		if (max) props.maxLength = max
		if (min) props.minLength = min

		return props
	}, [isjson, notsetted, clearable, debounce, readonly, min, max, required])

	const inputref = useRef(null)

	useEffect(() => {
		if (clearable && inputref.current) inputref.current.dispatchEvent(new Event("change")) 
	}, [clearable])

	const clear = useCallback(() => {
		if (required && inputref.current) inputref.current.setCustomValidity(requiredvalidation)
		debounce(undefined, true)
	}, [debounce, required])

	return (
		<React.Fragment>
			<SimpleLabel label={label} htmlFor={_id} />
			<div className="input-group input-group-sm" >
				<input id={_id} ref={inputref}
					className="form-control form-control-sm text-weight-normal"
					type={type}
					name={name}
					placeholder={placeholder}
					value={buffer || ''}
					{...props}
				/>
				<div className="input-group-append">
					{
						buffer === undefined || readonly || !clearable
							? <span className="input-group-text px-0"></span>
							: <span className="input-group-text px-1" style={({ cursor: "pointer" })} onClick={clear} >&times;</span>
					}
				</div>
			</div>
		</React.Fragment>
	)
}

export default SimpleInput;
