import React from 'react';
import { Switch, Route, Redirect, useParams, useLocation } from "react-router-dom";
import Page from './page'
import { DataContext } from '../app'
import * as isEqual from 'react-fast-compare';

// const defContext = {
//   scope: {
//     accounts: [],
//     current: {}
//   },
//   dispatch: () => {},
//   page: [],
//   entity: [],

//   shouldRefresh: false,
//   refreshing: false,
// } 

const SetScope = ({ accounts, dispatch, current }) => {
  const filter = useParams();
  const loc = useLocation();

  if (!accounts) return <div>wait...</div>

  const filtered = accounts.filter((acc) =>
    (!filter.empresa || filter.empresa === 'any' || filter.empresa == acc.empresa) &&
    (!filter.tipo || filter.tipo === 'any' || filter.tipo == acc.tipo) &&
    (!filter.id || filter.id === 'any' || filter.id == acc.id)
  )

  if (!filtered || !filtered.length) return <Redirect to="/unscoped" />

  if (!filtered.find((acc) => isEqual(acc, current))) //current scope isn't a valid one
    dispatch({ type: "set-account", payload: filtered[0] })

  //let parts = (filter.empresa ? 2 : 0) + (filter.tipo ? 2 : 0) + (filter.id ? 2 : 0)

  return <Redirect to={{
    ...loc,
    pathname: `/${loc.pathname.split("/").slice(7).join("/")}`
  }} />;
}

const Main = () => {
  return (
    <DataContext.Consumer>
      {
        (context) => {
          const pages = context.page && context.page.filter(page => page.type.split(";").some(tipo => (tipo === context.scope.current?.tipo || tipo === "public")))

          if (context.redirect) {
            const redirect = context.redirect
            context.dispatch({ type: "set", payload: { redirect: null } });
            return <Redirect to={redirect} />
          }

          if (!context.scope.current && !pages?.length) return <div><p>.</p><div className="alert alert-primary disabled bg-light-blue disabled color-palette m-3" role="alert" >Este usuario no tiene cuentas vinculadas</div></div>
          else if (pages && pages.length > 0)
            return (
              <Switch>
                <Route exact path="/">
                  <Redirect to={`/home`} />
                  {/*<Redirect to={`/inicio`} />*/}
                </Route>

                <Route path="/e/:empresa/t/:tipo/i/:id">
                  <SetScope dispatch={context.dispatch} accounts={context.scope.accounts} current={context.scope.current} />
                </Route>
                {
                  pages.map(({ ...page }) => {
                    //console.log("showing pags:", page);
                    return (
                      <Route
                        key={context.scope.current ? `${page.name}-${context.scope.current.empresa}-${context.scope.current.tipo}-${context.scope.current.id}` : page.name}
                        exact
                        path={`/${page.name}`} >
                        <Page context={context} {...page} />
                      </Route>
                    )
                  })
                }

                <Route path="/">
                  <div>not found</div>
                </Route>
              </Switch>
            )
          else return <div>Nada que mostrar</div>
        }
      }
    </DataContext.Consumer>
  )
}

export default Main
