import { updateObjectValue } from './utils'
import { execAction } from './actionlist/action';

export const reducer = (state, action) => {
	//console.log("page dispatch", action);
	let newstate = { ...state };;

	switch (action.type) {
		case "set-meta": //espera: {payload}
			newstate.meta = action.payload;
			newstate.meta.location.current = state.meta?.location.current

			//console.log("newstate", newstate);
			return newstate;
		case "set-data": //espera: {path, payload}
			updateObjectValue(newstate, action.path, action.payload, action.override || action.overwrite, action.autocreate)
			//console.log("newstate", newstate);
			return newstate;
		case "activate-form": //espera: {name, data?}
			if (!(newstate.forms instanceof Array)) newstate.forms = []
			newstate.forms.push({ name: action.name, data: JSON.parse(JSON.stringify(action.data)), reset: true, entity: action.entity })
			return newstate;
		case "show-module": //espera: {name, show}

			if (!(newstate.hidden instanceof Array)) newstate.hidden = []
			//console.log(action.show ? "showing" : "hidding", action.name, "from hidding: ", newstate.hidden);

			if (action.show) newstate.hidden = newstate.hidden.filter(board => (action.name instanceof Array ? !action.name.includes(board) : board !== action.name))
			else if (action.name instanceof Array) newstate.hidden.push(...(action.name))
			else newstate.hidden.push(action.name)

			return newstate;
		case "close-form": //espera: {index}
			if (!(newstate.forms instanceof Array)) newstate.forms = []

			if (isNaN(action.index)) newstate.forms = []
			else newstate.forms[action.index] = null

			if (!newstate.forms.filter(item => !!item).length) newstate.forms = []

			return newstate;
		default: throw Error("unknown action type:", action.type)
	}
}


export const observerReducer = (state, action) => {
	let buf;
	//console.log("observer", action);

	switch (action.type) {
		case "attach":
			if (!(buf = state[action.class || "timer"])) {
				state[action.class || "timer"] = buf = {}
			}

			if (buf[action.label]) {
				buf[action.label](); //dettaching
			}

			buf[action.label] = action.dettach;
			break;
		case "dettach":
			if (!(buf = state[action.class || "timer"])) {
				state[action.class || "timer"] = buf = {}
			}

			if (buf[action.label]) {
				buf[action.label](); //dettaching
			}

			delete buf[action.label];

			break;
		case "dettach-all":
			Object.entries(state).forEach(([k, val]) => {
				Object.entries(val).forEach(([k, dettach]) => {
					if (dettach) dettach();
				})
			})

			state = {};

			break;
		default: throw Error("unknown action type:", action.type, "for observerReducer")
	}
	return state;
}

export const actionReducer = (state, action) => {

	switch (action.type) {
		case "add":
			return [...state, action.actions]
		case "remove":
			if (state.length) {
				const curr = state.shift();
				if (curr?.tasks?.length) {
					return [{ ...curr, tasks: curr.tasks.slice(1) }, ...state]
				} else return [...state]
			}
			else {
				console.error("Can't remove not running tasks.", action);
				return state;
			}
		case "abort":
			if (state.length) {
				const curr = state.shift();
				if (curr?.tasks?.length) {
					return [{ ...curr, tasks: [curr.tasks[0]] }, ...state]
				} else {
					console.error("Can't abort ended tasks.", action);
					return [...state]
				}
			}
			else {
				console.error("Can't abort not running tasks.", action);
				return state;
			}
		case "add-tasks":
			if (state.length) {
				const curr = state.shift();
				if (curr?.tasks) {
					const newtasks = [...curr.tasks];
					newtasks.splice(1, 0, ...action.tasks)
					return [{ ...curr, tasks: newtasks }, ...state];
				} else return [...state]
			}
			else {
				console.error("Can't add tasks while not processing.", action);
				return state;
			}
		default:
			console.error("Wrong call on action dispatch.", action);
			return;
	}
}

export const actionProcessor = ({ currentAction, processing, action, actionDispatch, setProcessing, dispatch, data, currentMeta, currentScope }) => async () => {
	if (currentAction && !processing) {
		const { tasks, processingFlagPath, data: localData, ...props } = currentAction;
		const task = tasks && tasks[0]

		if (task) {
			let taskName, extraData = {}, actionProps;

			//console.log("preparing task", task);

			if (typeof task === "string") taskName = task; //simple named task
			else if (task instanceof Array) [taskName, extraData] = task; //task with extra data
			else ({ extraData, name: taskName, ...actionProps } = task); //routine inline task

			//if (actionProps && (!actionProps.type || actionProps.type === "global")) actionProps = undefined;

			if ((!actionProps || !actionProps.type || actionProps.type === "global") && taskName)
				actionProps = { ...actionProps, ...([{ name: "refreshed", type: "refreshed" }, ...action].find(action => action.name === taskName)) }

			if (!actionProps) {
				console.error(`Action ${taskName} not found. check page definition.`);
				actionDispatch({ type: "remove" });
			} else {
				setProcessing(true);

				const donefn = () => {
					if (processingFlagPath) dispatch({ type: "set-data", path: processingFlagPath, payload: tasks.length - 1, autocreate: false })

					actionDispatch({ type: "remove" });
					setProcessing(false);
				}

				const execParams = {
					action: actionProps,
					...props,
					data: { ...localData, ...extraData },
					dispatch: dispatch,
					outerData: data,
					meta: currentMeta,
					scope: currentScope,
					done: donefn

				}
				console.log(`running ${execParams.action.name || "unnamed"} @ ${execParams.action._stack || "/"}!`);

				execAction(execParams).then(result => { if (result !== "pause") donefn() })
			}
		} else {
			if (processingFlagPath) dispatch({ type: "set-data", path: processingFlagPath, payload: 0, autocreate: false })
			actionDispatch({ type: "remove" });
			console.log("finished!");
		}
	} //else console.log("nothing to do due:", processing, currentAction);
};
