import { Button } from 'bootstrap';
import React from 'react'
import { buttonCell, colGrow } from '../components/page/board/table/parsecolumns';
import { getObjectValue } from '../components/page/utils';

const getVal = (data, path) => {
  const val = getObjectValue(data, path);
  //console.log("from getVal:", data, path, "getting: ", val);
  
  return (val instanceof Array) ? val.reduce( (ini, cur) => {
    //ini = (ini instanceof Object ? getVal(ini, "") : ini && ini.toString())
    return (ini ? (ini + ", " ) : "") + (cur instanceof Object ? getVal(cur, "") : cur.toString())
  }, "") : (val instanceof Object ? getVal(val.value || val.label || val.title || val.name || val.id || null, "") : val)
}

const adjustright = ["number", "date", "time", "datetime-local"]

const Cell = ({ data, column, tableSize, asButton, index }) => {
  const value = getVal(data, column.path || column.name || "")
  let className = "";
  let cell;

  if (column.formatter) cell = column.formatter(data.currency).format(value)
  else cell = value

  className = adjustright.some(coltype => column.type === coltype) ? "text-right pr-3" : ""

    return <td style={{ width: `${Math.round(100.00 * colGrow(column.size) / tableSize)}%` } } className={className}>{asButton ? asButton(data, index) : cell }</td>
}

const getTableDataFromList = (_table, trClickHandler = () => { }, struct = [], meta) => {
    const tableSize = struct.reduce((acum, col) => acum + colGrow(col.size), 0.00 )
    return (
        <React.Fragment>
            <thead>
                <tr>
                    {struct.map(column => (column.hideonboard ? null : <th style={{ width: `${ Math.round(100.00 * colGrow(column.size) / tableSize) }%` }} key={column.name}>{column.title || column.label || column.name}</th>))}
                    {/* {Object.keys(_table[0]).map((elm, i) => (<th key={i}>{elm}</th>))} */}
                </tr>
            </thead>
            <tbody>
                {(_table instanceof Array) && _table.map((elm, i) => (
                    <tr key={elm.id || (i + 1)} onClick={trClickHandler(elm)}>
                        {
                            struct.map(column => column.hideonboard
                                ? null
                                : <Cell column={column} data={elm} index={i} key={column.name} tableSize={tableSize} asButton={column.type === "button" ? buttonCell(meta, column) : null} />)
                        }
                    </tr>
                ))}
            </tbody>
        </React.Fragment>
    )
}
const getTableData = (_result) => (
  <React.Fragment>
    <thead>
      <tr>
        {_result.columns.map((elm, idx) => (<th key={idx}>{elm.name}</th>))}
      </tr>
    </thead>
    <tbody>
      {_result.rows.map((row, idx) => (
        <tr key={idx}>
          {row.map((item, idx) => (
            <td key={idx}>
              {item instanceof Object ? "" : item}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </React.Fragment>
)


export default { getTableData, getTableDataFromList };
