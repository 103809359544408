import React from 'react'
import { ApplicationPaths, QueryParameterNames as ParNames  } from './constants'
import FrontDoor from '../alert/frontdoor'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

const LoggedOut = () => {
    const queryObj = {};

    queryObj[ParNames.ReturnUrl] = queryString.parse(useLocation().search)[ParNames.ReturnUrl]

    const link = queryString.stringifyUrl({
        url: ApplicationPaths.Login,
        query: queryObj,
    })

    return <FrontDoor message="No has iniciado sesión" button="Acceder" link={link} />;
}
export default LoggedOut
